import router from "@/router";

export default {
    // 倒计时函数，作废
    countTime(endTime) {
        //获取当前时间
        var date = new Date();
        var now = date.getTime();
        //设置截止时间
        var endDate = new Date(endTime);
        var end = endDate.getTime();
        //时间差
        var leftTime = end - now;
        //定义变量 d,h,m,s保存倒计时的时间
        let d = ''
        let h = ''
        let m = ''
        let s = ''
        if (leftTime >= 0) {
            d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
            h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
            m = Math.floor((leftTime / 1000 / 60) % 60);
            s = Math.floor((leftTime / 1000) % 60);
        }
        setTimeout(this.countTime, 1000);
        return `${d}天${h}小时${m}分钟`
        //递归每秒调用countTime方法，显示动态时间效果

    },
    isAppleWebKit() {
        var userAgent = navigator.userAgent.toLowerCase();
        return userAgent.indexOf('applewebkit') !== -1 &&
            userAgent.indexOf('chrome') === -1 &&
            userAgent.indexOf('chromium') === -1 &&
            userAgent.indexOf('crios') === -1 &&
            userAgent.indexOf('fxios') === -1 &&
            userAgent.indexOf('safari') === -1;
    }
}