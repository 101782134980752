<!-- 可拖拽的小球 封装 -->
<template>
  <div class="Floating">
    <div
      ref="floatButton"
      class="backHome"
      @click.stop="showPopup"
      @touchstart="touchstart"
      @touchmove="touchmove"
      @touchend="touchend"
      :style="{
        width: itemWidth + 'px',
        height: itemHeight + 'px',
        left: left + 'px',
        top: top + 'px',
      }"
    >
      <img v-if="netDelay <= 49" src="../assets/img/xuanfu.png" />
      <img
        v-else-if="netDelay >= 50 && netDelay < 200"
        src="../assets/img/xuanfu1.png"
      />
      <img v-else-if="netDelay >= 200" src="../assets/img/xuanfu2.png" />
    </div>
  </div>
</template>
 
<script>
export default {
  name: "Floating",
  props: {
    itemWidth: {
      // 悬浮按钮宽度
      type: Number,
      default: 60,
    },
    itemHeight: {
      // 悬浮按钮高度
      type: Number,
      default: 60,
    },
    gapWidth: {
      // 距离左右两边距离
      type: Number,
      default: 0,
    },
    coefficientHeight: {
      // 从上到下距离比例
      type: Number,
      default: 0.72,
    },
    netDelay: {
      type: Number,
      default: 10,
    },
    floatPhone: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      top: 0,
      left: 0,
      currentTop: 0,
      clientWidth: 0,
      clientHeight: 0,
      timer: null,
      show: false,
      floatPlace: [],
    };
  },
  created() {
    this.clientWidth = document.documentElement.clientWidth; // 手机宽度
    this.clientHeight = document.documentElement.clientHeight;
    this.getPlace();
  },
  mounted() {},
  methods: {
    // 展开菜单
    showPopup() {
      this.$emit("xuanfuchuan");
    },
    getPlace() {
      this.floatPlace = JSON.parse(localStorage.getItem("floatPlace")) || [];
      let exist = true;
      if (this.floatPlace && this.floatPlace.length > 0) {
        for (let i = 0; i < this.floatPlace.length; i++) {
          if (this.floatPhone.id == this.floatPlace[i].id) {
            this.left = this.floatPlace[i].left;
            this.top = this.floatPlace[i].top;
            exist = false;
          }
        }
      } else {
        this.left = this.clientWidth - this.itemWidth - this.gapWidth;
        this.top = this.clientHeight * this.coefficientHeight;
        return;
      }
      if (exist) {
        this.left = this.clientWidth - this.itemWidth - this.gapWidth;
        this.top = this.clientHeight * this.coefficientHeight;
      }
    },
    touchstart() {
      const floatButton = this.$refs.floatButton;
      floatButton.style.transition = "none";
      document.body.style.overflow = "hidden";
    },
    touchmove(e) {
      if (e.targetTouches.length === 1) {
        let touch = e.targetTouches[0];
        this.left = touch.clientX - 20;
        this.top = touch.clientY - 25;
      }
      //4、限制拖拽宽高
      if (this.left < 0) {
        this.left = 0;
        //如果拖拽元素定位的数值高于, 页面可视宽 - 拖拽元素自身宽 (可视页面最大宽)
      } else if (this.left > this.clientWidth - this.itemWidth) {
        //满足这个条件，就限制宽为，clientWidth-oDiv.offsetWidth(可视页面最大宽)
        this.left = this.clientWidth - this.itemWidth;
      }

      if (this.top < 0) {
        this.top = 0;
        //如果拖拽元素定位的数值高于, 页面可视高 - 拖拽元素自身高 (可视页面最大高)
      } else if (this.top > this.clientHeight - this.itemHeight) {
        //满足这个条件，就限制高为，clientHeight-oDiv.offsetHeight(可视页面最大高)
        this.top = this.clientHeight - this.itemHeight;
      }
    },
    touchend() {
      // const floatButton = this.$refs.floatButton;
      // floatButton.style.transition = "all 0.3s";
      // // document.body.style.overflow = "auto";
      // if (this.left > document.documentElement.clientWidth / 2) {
      //   this.left = this.clientWidth - this.itemWidth - this.gapWidth;
      //   setTimeout(() => {
      //     this.left = this.clientWidth - this.itemWidth - this.gapWidth + 40;
      //   }, 2000);
      // } else {
      //   this.left = 0;
      //   setTimeout(() => {
      //     this.left = this.left - 40;
      //   }, 2000);
      // }
      this.floatPhone.left = this.left;
      this.floatPhone.top = this.top;
      for (let i = 0; i < this.floatPlace.length; i++) {
        if (this.floatPlace[i].id == this.floatPhone.id) {
          this.floatPlace.splice(i, 1);
        }
      }
      this.floatPlace.push(this.floatPhone);
      localStorage.setItem("floatPlace", JSON.stringify(this.floatPlace));
    },
  },
};
</script>
 
<style lang="less" scoped>
.backHome {
  position: fixed;
  z-index: 999;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>