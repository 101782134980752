import router from './router'
// 移动端不需要配置路由进度条
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'

// NProgress.configure({ showSpinner: false })

// 登录，，拼团，下载，发卡，发卡-->激活码+历史激活码+激活码详情
const whiteList = ['/publicity', '/login', '/oneKeyLogin', '/register', '/groupbooking', '/download', '/issuingplatform', '/activationcode', '/keyParticulars', '/historyKey', '/invitelandingpage', '/commissionsharing', '/', '/correlWe', "/Activetemplate", '/activebuy', '/forgotpassword', '/retrievepassword', '/springFestival', '/wxMiddlePage', '/attemptPlay', '/DNFgroupoid', '/landingPageDNF', '/pageBrand', '/sixOneEightFestival', '/clientServe', "/channelPreloaded"]
router.beforeEach((to, from, next) => {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
  // NProgress.start()
  let _token = JSON.parse(localStorage.getItem('token'))
  if (_token) {
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      // NProgress.done()
    } else {
      next()
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      // 否则全部重定向到登录页
      next(`login`)
      // next()
      // NProgress.done()
    }
  }

})

// router.afterEach(() => {
//   NProgress.done()
// })
