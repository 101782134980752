<template>
  <div class="login">
    <div class="serviceBox" @click="zaixiankefu">
      <img
        class="serviceClass"
        src="../../assets/img/mineAmend/serviceIcon@2x.png"
        alt=""
      />
      <div class="serviceName">联系客服</div>
    </div>

    <div class="main">
      <p class="yunshouji">欢迎来到傲晨云手机</p>
      <div class="denglu">
        <div class="shoujihao">
          <span class="inputBoxName">手机号</span>
          <van-cell-group :class="jiaodian ? 'huoqujiaodian' : 'shiqujiaodian'">
            <van-field
              v-model="phone"
              type="tel"
              placeholder="请输入手机号"
              @focus="huoqu"
              @blur="shiqu"
              maxlength="11"
            />
          </van-cell-group>
        </div>
        <div v-if="Switchlogin" class="yanzhengma">
          <span class="inputBoxName">验证码</span>
          <van-cell-group
            :class="jiaodians ? 'huoqujiaodian' : 'shiqujiaodian'"
          >
            <van-field
              v-model="yanzhengma"
              placeholder="请输入验证码"
              @focus="huoqus"
              @blur="shiqus"
              maxlength="6"
            >
              <template #button>
                <van-button
                  v-if="!yzm_daojishi"
                  size="small"
                  class="yzm_btn"
                  @click="getyanzhengma"
                  >获取验证码</van-button
                >
                <van-button v-else size="small" class="yzm_btn" :disabled="true"
                  >重新发送（{{ countDown }}）</van-button
                >
              </template>
            </van-field>
          </van-cell-group>
        </div>
        <div v-else class="yanzhengma">
          <span>密码登录</span>
          <van-cell-group
            :class="jiaodians ? 'huoqujiaodian' : 'shiqujiaodian'"
          >
            <van-field
              v-model="password"
              :type="showornot"
              name="密码"
              placeholder="请输入密码"
              :rules="[{ required: true, message: '请填写密码' }]"
            >
              <template #button>
                <span @click="isShow">
                  <van-icon name="closed-eye" v-if="showeye" />
                  <van-icon name="eye-o" v-else />
                </span>
              </template>
            </van-field>
          </van-cell-group>
        </div>
        <div v-if="Switchlogin" class="qiehuandl">
          <span @click="Switchlogin = false">密码登录</span>
        </div>
        <div v-else class="qiehuandl">
          <span @click="Switchlogin = true">验证码登录</span>
          <p></p>
          <span class="span1" @click="wangjimimma">忘记密码</span>
        </div>
        <van-button
          type="info"
          class="btn"
          :disabled="
            checked & (yanzhengma != '') & (phone != '') ||
            checked & (password != '') & (phone != '')
              ? false
              : true
          "
          :color="
            checked & (yanzhengma != '') & (phone != '') ||
            checked & (password != '') & (phone != '')
              ? ''
              : '#B2B4BA'
          "
          @click="mobiledenglu"
          >登录</van-button
        >
        <div class="xieyi">
          <van-checkbox v-model="checked" shape="square"
            >已阅读并同意<a
              href="http://static.aochenyun.com/app/user_agreement.html  "
              >《用户使用协议》</a
            >和<a href="http://static.aochenyun.com/app/privacy_policy.html "
              >《隐私政策》</a
            >内所有条款</van-checkbox
          >
        </div>
      </div>
    </div>
    <van-overlay class-name="overlayName" :show="overlayShow">
      <div class="wrapper" @click="overlayShow = false">
        <img src="../../assets/img/homeAmend/bootPage.png" alt="" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  getsendCode,
  mobileLogin,
  accountlogin,
  oneClickLogin,
} from "../../api/index.js";
export default {
  name: "AochenyunshoujiLogin",

  data() {
    return {
      phone: "",
      yanzhengma: "",
      checked: false,
      yzm_daojishi: false,
      jiaodian: false,
      jiaodians: false,
      countDown: 60, // 倒计时时间
      timer: null, // 定时器
      password: "",
      showeye: true,
      showornot: "password",
      Switchlogin: true,
      overlayShow: false,
    };
  },
  created() {
    this.gitAccredit();
  },
  mounted() {
    let that = this;
    let channelCode;
    if (that.$route.query.channelCode) {
      channelCode = { channelCode: atob(that.$route.query.channelCode) };
      sessionStorage.setItem("channelCode", JSON.stringify(channelCode));
    }
    // else {
    //   channelCode = { channelCode: "" };
    //   sessionStorage.setItem("channelCode", JSON.stringify(channelCode));
    // }
  },
  beforeDestroy() {
    this.overlayShow = false;
  },

  methods: {
    zaixiankefu() {
      this.$router.push("/clientServe");
    },
    // 判断网络方法
    getNetworkType() {
      var common_cell = ["cellular", "2g", "3g", "4g", "5g", "3G/2G"];
      var user_agent = navigator.userAgent.toLowerCase();
      var connection = navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection || { type: "unknown" };
      var type;
      // 判断是否是微信内置浏览器
      var isWeixin = /micromessenger/.test(user_agent);
      if (isWeixin) {
        if (user_agent.indexOf("nettype") !== -1) {
          type = user_agent.match(/nettype\/\w+/)
            ? user_agent.match(/nettype\/\w+/)[0]
            : "nettype/unknow";
          type = type.replace("nettype/", "");
        } else {
          var weixinType = {
            "network_type:wifi": "wifi",
            "network_type:edge": "3G/2G",
            "network_type:fail": "fail",
            "network_type:wwan": "3G/2G",
          };
          document.addEventListener(
            "WeixinJSBridgeReady",
            function onBridgeReady() {
              WeixinJSBridge.invoke("getNetworkType", {}, function (e) {
                type = weixinType[e.err_msg];
              });
            }
          );
        }
      } else {
        type = (connection && connection.type) || "unknown";
      }
      // 对type 做统一处理
      if (type && common_cell.indexOf(type) !== -1) {
        return "cellular";
      } else if (type === "wifi") {
        return "wifi";
      }
      return "unknown";
    },
    // 初始化 onelogin API
    initOl() {
      let olinstance = new GOL({
        app_id: "40ecdbd4b8999c0bf188dd4c1d895be1", // 填写您在后台申请已审核通过的 appID
        // logo:'https://mem.wostore.cn/h5netauth/resource/images/login/unicom_logo.png', // 此处是示例代码
        app: "傲晨云手机",
        logo: "https://aochen-prod.oss-cn-shenzhen.aliyuncs.com/files/image/ic_96.png", // 此处是示例代码
      });
      // step4: 成功失败回调
      olinstance
        .onTokenFail((e) => {
          this.overlayShow = false;
          // 获取token失败 走降级处理 走短信或者其他登录方式 用户可根据业务做不同判断
        })
        .onTokenSuccess((e) => {
          this.overlayShow = true;
          // e：获取token成功后返回object包含： {token: '', phone: '', process_id: '', auth_code: ''}
          // const data = {
          //   app_id: "appid", // 填写您在后台申请已审核通过的 appID
          //   ...e, // 获取token成功后的数据 包含了 token, phoen ,process_id,auth_code(电信)
          // };
          let data = {
            processId: e.process_id,
            token: e.token,
            authCode: e.auth_code,
            mobile: e.phone,
          };
          oneClickLogin(data).then((res) => {
            if (res.code == 200) {
              res.data.loginType = "4";
              localStorage.setItem("data", JSON.stringify(res.data));
              localStorage.setItem("token", JSON.stringify(res.data.token));
              if (res.data.isRegister == 0) {
                this.$router.push({
                  path: "/enrollJumpPassword",
                  query: { phone: e.phone },
                });
              } else {
                this.$router.push("/");
              }
            } else {
              Toast(res.msg);
              this.overlayShow = false;
            }
          });
        });
      return olinstance;
    },
    gitAccredit() {
      var initOlinstance;
      // 可提前判断网络状态 决定是否初始化H5一键登录
      var nettype = this.getNetworkType();
      // 根据客户业务进行判断 网络判断返回 cellular、 wifi、 unknown
      // unkonow： 一般在iOS系统中获取不到网络状态，此时可正常走一键登录，未开启热点流量数据时校验失败不计费
      if (nettype === "wifi") {
        // 可选方案：
        // 1: 提示用户关闭wifi 进行体验
        // 2: 直接降级走其他验证形式
      } else {
        // stpe3: 初始化H5一键登录
        initOlinstance = this.initOl();
      }
      // 模拟点击登录场景 可以在页面加载的时候判断网络状态后初始化就调用gateway方法
      // $("#test").click(() => {
      // 调用H5一键登录
      if (!initOlinstance) {
        // 降级走其他验证方式
      }
      // step5: 调用H5 一键登录
      initOlinstance && initOlinstance.gateway();
      // });
    },

    getSearchString(key) {
      var str = window.location.search;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      return obj[key];
    },
    huoqu() {
      this.jiaodian = true;
    },
    huoqus() {
      this.jiaodians = true;
    },
    shiqu() {
      this.jiaodian = false;
    },
    shiqus() {
      this.jiaodians = false;
    },
    // 获取验证码
    async getyanzhengma() {
      const phoneReg = /^1[3456789]\d{9}$/;
      if (!phoneReg.test(this.phone)) {
        Toast("手机号错误！请重新输入");
      } else {
        let data = {
          mobile: this.phone,
          businessType: "login",
        };
        await getsendCode(data).then((res) => {
          if (res.code == 200) {
            this.startCountDown();
            Toast("验证码已发送至您的手机");
          } else {
            Toast(res.msg);
          }
        });
      }
    },
    // 登录
    async mobiledenglu() {
      const phoneReg = /^1[3456789]\d{9}$/;
      if (!phoneReg.test(this.phone)) {
        Toast("手机号错误！请重新输入");
      } else {
        if (this.Switchlogin) {
          let data = {
            mobile: this.phone,
            code: this.yanzhengma,
          };
          await mobileLogin(data).then((res) => {
            if (res.code == 200) {
              res.data.loginType = "0";
              localStorage.setItem("data", JSON.stringify(res.data));
              localStorage.setItem("token", JSON.stringify(res.data.token));
              if (res.data.isRegister == 0) {
                this.$router.push({
                  path: "/enrollJumpPassword",
                  query: { phone: this.phone },
                });
              } else {
                this.$router.push("/");
              }
            } else {
              Toast(res.msg);
            }
          });
        } else {
          let data = {
            phoneNumber: this.phone,
            password: this.password,
          };
          await accountlogin(data).then((res) => {
            if (res.code == 200) {
              res.data.loginType = "0";
              localStorage.setItem("data", JSON.stringify(res.data));
              localStorage.setItem("token", JSON.stringify(res.data.token));
              this.$router.push("/");
            } else {
              Toast(res.msg);
            }
          });
        }
      }
    },
    // 验证码倒计时
    startCountDown() {
      this.yzm_daojishi = true;
      this.timer = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--;
        } else {
          clearInterval(this.timer);
          this.yzm_daojishi = false;
          this.countDown = 60;
        }
      }, 1000);
    },
    //密码可见
    isShow() {
      //点击切换眼睛
      this.showeye = !this.showeye;
      if (this.showeye === true) {
        this.showornot = "password";
      } else {
        this.showornot = "text";
      }
    },
    // 忘记密码跳转
    wangjimimma() {
      this.$router.push("/forgotpassword");
    },
  },
};
</script>

<style lang="less" scoped>
.inputBoxName {
  font-size: 14px;
}
.overlayName {
  .wrapper {
    width: 100%;
    height: 100%;
    background: #fff;
    img {
      width: 100%;
      // height: 100%;
    }
  }
}
.login {
  padding: 64px 28px 0;
}
.serviceBox {
  position: fixed;
  top: 12px;
  right: 12px;
  .serviceClass {
    width: 22px;
    height: 22px;
  }
  .serviceName {
    font-size: 8px;
    line-height: 8px;
  }
}

.main {
  text-align: left;

  .yunshouji {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 50px;
  }
  .denglu {
    span {
      font-size: 12px;
    }
    /deep/ .van-hairline--top-bottom::after {
      border: none;
    }
    .shoujihao {
      font-size: 16px;
      margin-bottom: 16px;
    }
    .yanzhengma {
      // height: 72px;
      font-size: 16px;
      margin-bottom: 16px;
      .yzm_btn {
        border: none;
      }
      /deep/.van-field__body {
        height: 33px;
      }
    }
    .qiehuandl {
      display: flex;
      align-items: center;
      height: 32px;
      margin-top: -10px;
      p {
        flex: 1;
      }
      span {
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-top: 5px;
      }
      .span1 {
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #0066ff;
        line-height: 20px;
      }
    }
  }
  .shiqujiaodian {
    border-bottom: 1px solid #f4f5f9;
    margin-top: 10px;
  }
  .huoqujiaodian {
    border-bottom: 1px solid #558bff;
    margin-top: 10px;
  }
}
.btn {
  margin-top: 16px;
  width: 100%;
  span {
    font-size: 16px !important;
  }
}
.xieyi {
  font-size: 12px;
  margin: 16px 0 auto;
  a {
    color: #558bff;
  }
}
/deep/ .van-checkbox__label {
  font-size: 12px;
}
</style>