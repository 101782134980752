import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const load = [
    {
        path: '/download',
        name: 'Download',
        component: () => import('../../views/dowload/dowload.vue')
    },
    {
        path: '/publicity',
        name: 'Publicity',
        component: () => import('../../views/dowload/publicity.vue')
    },
    {
        path: '/correlWe',
        name: 'CorrelWe',
        component: () => import('../../views/dowload/correlWe.vue')
    },
    {
        path: '/landingPageDNF',
        name: 'LandingPageDNF',
        component: () => import('../../views/dowload/landingPageDNF.vue')
    },
    {
        path: '/pageBrand',
        name: 'PageBrand',
        component: () => import('../../views/dowload/pageBrand.vue')
    },
]

export default load
